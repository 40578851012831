import { template as template_7ac57f2516cb4258a331b6f4b92cf2ef } from "@ember/template-compiler";
const SidebarSectionMessage = template_7ac57f2516cb4258a331b6f4b92cf2ef(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
