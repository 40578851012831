import { template as template_291db910c1fa4cd38b0da1eacd815b31 } from "@ember/template-compiler";
const FKLabel = template_291db910c1fa4cd38b0da1eacd815b31(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
