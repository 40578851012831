import { template as template_c507dd6f4eac4ae5a8d79c1a5eaa61ad } from "@ember/template-compiler";
const FKControlMenuContainer = template_c507dd6f4eac4ae5a8d79c1a5eaa61ad(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
